<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';


	/* loader page */
	Vue.component("LoaderPage", () => import("@frontend/part/LoaderPage.vue"))

	export default {
		extends: BaseVue,
		data() {
			return {
				data_slider: {},
				data_about_us: {},
				data_collection: {},
				data_favorite: {},
				data_event: {},
				data_latest_blog: {},
				show_about_us: {},
				show_favorite: {},
				show_event: {},
				show_blog: {},
				show_feed: {},
				data_feed: {},
				onReady: false
			}
		},
		async created() {
			await Gen.loadScript("/frontend/include/rs-plugin/js/jquery.themepunch.tools.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/jquery.themepunch.revolution.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.video.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.slideanims.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.actions.min.js")
			await Gen.loadScript(
				"/frontend/include/rs-plugin/js/extensions/revolution.extension.layeranimation.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.kenburn.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.navigation.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.migration.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.parallax.min.js")
		},
		computed: {
			isCookie() {
				return Gen.getStorage('privacyCookie') ? false : true
			},
            isNotDesktop(){
                return this.isMobile || this.isTablet ? true : false
            }
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.$set(this.$root, 'page', this);
			this.getApi()
			this.getInstagram()

			$(window).scroll(function (event) {
				var scroll = $(window).scrollTop();
				if (scroll == 0) {
					$('#header').removeClass('sticky-header');
				}
			});
		},
		methods: {
			getApi(all = false) {
				Gen.apirest("/content-home", {}, (err, resp) => {
					if (err) console.log(err)
					this.data_slider = resp.data.slider
					this.data_favorite = resp.data.favorite
					if(all) return
					this.data_about_us = resp.data.about_us
					this.data_collection = resp.data.collection
					this.data_event = resp.data.event
					this.data_latest_blog = resp.data.latest_blog
					this.show_about_us = resp.data.show_about_us
					this.show_favorite = resp.data.show_favorite
					this.show_event = resp.data.show_event
					this.show_blog = resp.data.show_blog
					this.show_feed = resp.data.show_feed
					// this.data_feed = resp.data.data_feed
					setTimeout(() => {
						this.initParalax();
						SEMICOLON.widget.carousel()
					}, 50);
				})
			},
			getAccessInstagram() {
				Gen.apirest("/web-settings", {id:22}, (err, resp) => {
					if (err) console.log(err)
					this.getFeedInstagramCape(resp.data.aws_val)
				})
			},
			getInstagram() {
				Gen.apirest("/get-instagram", {}, (err, resp) => {
					if (err) console.log(err)
					this.data_feed = resp.data
				})
			},
			getFeedInstagramCape(user_token) {
				$.ajax({
					url: 'https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp&access_token=' + user_token,
					method: 'get',
					success: (data) => {
						this.$set(this, "data_feed", data.data)
					}
				});
			},
			initParalax() {
				var tpj = jQuery;
				var revapi31;
				// tpj(document).ready(function () {
				if (tpj("#rev_slider_679_1").revolution == undefined) {
					revslider_showDoubleJqueryError("#rev_slider_679_1");
					setTimeout(() => { this.onReady = true }, 300);
				} else {
					revapi31 = tpj("#rev_slider_679_1").show().revolution({
						sliderType: "standard",
						jsFileLocation: "include/rs-plugin/js/",
						sliderLayout: "fullwidth",
						dottedOverlay: "none",
						delay: 16000,
						hideThumbs: 200,
						thumbWidth: 100,
						thumbHeight: 50,
						thumbAmount: 5,
						navigation: {
							keyboardNavigation: "on",
							keyboard_direction: "horizontal",
							mouseScrollNavigation: "off",
							onHoverStop: "off",
							touch: {
								touchenabled: "on",
								swipe_threshold: 75,
								swipe_min_touches: 1,
								swipe_direction: "horizontal",
								drag_block_vertical: false
							},
							arrows: {
								style: "hades",
								enable: true,
								hide_onmobile: false,
								hide_onleave: false,
								tmp: '<div class="tp-arr-allwrapper">	<div class="tp-arr-imgholder"></div></div>',
								left: {
									h_align: "left",
									v_align: "center",
									h_offset: 10,
									v_offset: 0
								},
								right: {
									h_align: "right",
									v_align: "center",
									h_offset: 10,
									v_offset: 0
								}
							},
						},
						responsiveLevels: [1140, 1024, 778, 480],
						visibilityLevels: [1140, 1024, 778, 480],
						gridwidth: [1140, 1024, 778, 480],
						gridheight: [600, 600, 600, 600],
						lazyType: "none",
						shadow: 0,
						spinner: "off",
						stopLoop: "off",
						stopAfterLoops: -1,
						stopAtSlide: -1,
						shuffle: "on",
						autoHeight: "off",
						fullScreenAutoWidth: "off",
						fullScreenAlignForce: "off",
						fullScreenOffsetContainer: "",
						fullScreenOffset: "0px",
						hideThumbsOnMobile: "off",
						hideSliderAtLimit: 0,
						hideCaptionAtLimit: 0,
						hideAllCaptionAtLilmit: 0,
						debugMode: false,
						fallbacks: {
							simplifyAll: "off",
							nextSlideOnWindowFocus: "off",
							disableFocusListener: false,
						}
					});
				}
				revapi31.bind("revolution.slide.onloaded", (e) => {
					setTimeout(function () { SEMICOLON.slider.sliderParallaxDimensions(); }, 200);
					setTimeout(() => { this.onReady = true }, 300);
				});
				revapi31.bind("revolution.slide.onchange", function (e, data) {
					SEMICOLON.slider.revolutionSliderMenu();
				});
				// }); /*ready*/
			},
			closeCookie() {
				var x = document.getElementById("myDIV");
				Gen.putStorage('privacyCookie', 'true')
				if (x.style.display === "none") x.style.display = "block";
				else x.style.display = "none";
			},
			addFavoriteHome(v){
		      if(this.user){
		        Gen.apirest("/post-favorite",{data:v.apd_id,id:this.user.u_id},(err,resp)=>{
		            if(err) console.log(err)
		              if(resp.status === 'Y'){
		                  $('[data-id="'+v.apd_id+'"]').find('i').removeClass('icon-line-heart');
		                  $('[data-id="'+v.apd_id+'"]').find('i').addClass('icon-heart3');
		              } else {
		                  $('[data-id="'+v.apd_id+'"]').find('i').removeClass('icon-heart3');
		                  $('[data-id="'+v.apd_id+'"]').find('i').addClass('icon-line-heart');
		              }
		        })
		      } else {
		        this.$router.push({name:'Login'})
		      }
		    }
		},
	};
</script>
<template>
	<div>
		<div class="box_cookie" id="myDIV" v-if="isCookie">
			<p>This website uses cookies to improve your experience.
				By using this website you agree to our <a href="https://en.wikipedia.org/wiki/HTTP_cookie"
					target="_blank"> Cookie Policy.</a></p>
			<p><a href="javascript:;" @click="closeCookie" class="btn_cookie">Ok</a></p>
		</div>
		<section id="slider" class="slider-element revslider-wrap clearfix">
			<div class="slider-parallax-inner">
				<div id="rev_slider_679_1_wrapper" class="rev_slider_wrapper fullwidth-container" style="padding:0px;">
					<div id="rev_slider_679_1" class="rev_slider fullwidthbanner" style="display:none;"
						data-version="5.1.4">
						<ul>
							<li v-for="(v,k) in data_slider" :key="k" class="dark" :data-transition="v.img_motion"
								data-slotamount="1" data-masterspeed="1500" :data-thumb="uploader(v.image)"
								data-delay="2000" data-saveperformance="off" data-title="Unlimited Possibilities">
								<img :src="uploader(v.image)" :alt="v.title" :title="v.title"
									data-bgposition="center bottom" data-bgpositionend="center top" data-kenburns="on"
									data-duration="10000" data-ease="Linear.easeNone" data-scalestart="120"
									data-scaleend="100" data-rotatestart="0" data-rotateend="0" data-blurstart="0"
									data-blurend="0" data-offsetstart="0 0" data-offsetend="0 0" class="rev-slidebg"
									data-no-retina>
								<div class="tp-caption customin ltl tp-resizeme revo-slider-emphasis-text nopadding noborder"
									data-x="middle" data-hoffset="0" data-y="top" data-voffset="230"
									:data-fontsize="v.title_font_size"
									data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;s:800;e:Power4.easeOutQuad;"
									data-speed="10000" data-start="800" data-easing="easeOutQuad" data-splitin="none"
									data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1"
									data-endspeed="1000" data-endeasing="Power4.easeIn"
									:style="'z-index: 3; white-space: nowrap; color:'+v.title_font_color+'; font-family: '+v.title_font_type+'; '">
									{{v.title}}</div>
								<div class="tp-caption customin ltl tp-resizeme revo-slider-desc-text" data-x="middle"
									data-hoffset="0" data-y="top" data-voffset="340"
									data-width="['650','650','480','360']"
									data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;s:800;e:Power4.easeOutQuad;"
									data-speed="800" data-start="1400" data-easing="easeOutQuad" data-splitin="none"
									data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1"
									data-textAlign="center" data-endspeed="1000" data-endeasing="Power4.easeIn"
									style="z-index: 3; max-width: 550px;  color:#fff; letter-spacing:0.16rem; line-height:1.5; white-space: normal; font-size:25px;font-family: 'Avian';  font-size:25px;">
									<p>{{v.description}}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>

		<section id="content" v-if="!onReady" style="background-color: #242422; overflow: visible;height:600px">
			<div class="content-wrap pt-0 pb-0">
				<div id="section-collwction"
					class="section notopmargin nobottommargin page-section bg_dark_grey clearfix">
					<center class="loading_home">
						<LoaderPage></LoaderPage>
					</center>
				</div>
			</div>
		</section>

		<section id="content" v-show="onReady" style="background-color: #000; overflow: visible;">
			<div class="content-wrap pt-0 pb-0">
				<div v-if="show_about_us=='true'" id="section-about"
					class="section bor_section page-section bg_white bor_section nobottommargin notopmargin clearfix">
					<div class="container clearfix">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section_light">
									<h2>{{web.mn_about}}</h2>
								</div>
							</div>
							<div class="col-md-12 col-lg-5 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
								<div class="about_wrap">
									<h3 class="txt_ccc">
										{{data_about_us.title}}
									</h3>
									<div v-html="(data_about_us.description||'').limitChar(498)"></div>
									<p>
										<router-link :to="{name:'About'}" class="btn_act d-none d-md-inline-block">
											{{web.lbl_read_more}}</router-link>
									</p>
								</div>
							</div>
							<div class="col-md-12 col-lg-6 col-sm-12">
								<div class="row">
									<div class="col-6 pad_right_7" data-aos="fade-up" data-aos-duration="2000"
										data-aos-delay="350">
										<div class="wrap_img_about">
											<img :src="uploader(data_about_us.asc_img1)" :alt="data_about_us.title"
												:title="data_about_us.title" />
										</div>
									</div>
									<div class="col-6 pad_left_7" data-aos="fade-up" data-aos-duration="3000"
										data-aos-delay="350">
										<div class="wrap_img_about">
											<img :src="uploader(data_about_us.asc_img2)" :alt="data_about_us.title"
												:title="data_about_us.title" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
				<div id="section-collection" v-if="data_collection.length"
					class="section notopmargin nobottommargin page-section bor_section bg_white clearfix">
					<div class="container">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section">
									<h2>{{web.lbl_our_collection}}</h2>
								</div>
							</div>
						</div>
						<div v-if="!isNotDesktop" class="row justify-content-center">
							<div class="col-md-3" v-for="(v,k) in data_collection" :key="k">
								<a :href="v.parent_link" class="team"
									data-aos="fade-up" data-aos-duration="300">
									<div class="team-image">
										<img :src="uploader(v.image)" :alt="v.name" :title="v.name">
									</div>
									<div class="team-desc">
										<div class="team-title">
											<h4>{{v.name}}</h4>
										</div>
									</div>
								</a>
							</div>
						</div>
						<div v-else class="owl-carousel carousel-widget" data-autoplay="5000" data-loop="true"
							data-margin="10" data-items-xs="1" data-items-md="3" data-items-sm="1" data-pagi="true" data-nav="false"
							data-stage-padding="30">
							<a :href="v.parent_link" class="team"
								v-for="(v,k) in data_collection" :key="k">
								<div class="team-image">
									<img :src="uploader(v.image)" :alt="v.name" :title="v.name">
								</div>
								<div class="team-desc">
									<div class="team-title">
										<h4>{{v.name}}</h4>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div class="clear"></div>
				<div id="section-collection" v-if="show_favorite=='true' && data_favorite.length"
					class="section notopmargin nobottommargin page-section bor_section bg_white  clearfix">
					<div class="container">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section_light">
									<h2>{{web.lbl_customer_favourite}}</h2>
								</div>
							</div>
						</div>
						<div class="justify-content-center tight">
							<div id="oc-clients" class="owl-carousel image-carousel carousel-widget" data-margin="20"
								data-loop="false" data-nav="false" data-autoplay="5000" data-pagi="false"
								data-items-xs="2" data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="5">
								<div class="oc-item" v-for="(v,k) in data_favorite" :key="k">
									<div class="wrap_prod_list">
										<div class="wrap_prod_thumb">
											<router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
												<img :src="uploader(v.apd_image)" :alt="v.apd_name" :title="v.apd_name"/>
											</router-link>
											<a href="javascript:;"  :data-id="v.apd_id"  @click="addFavoriteHome(v)" class="btn_fav">
												<i v-show="v.isFavorite=='Y'" class="icon-heart3"></i>
												<i v-show="v.isFavorite=='N'" class="icon-line-heart"></i>
											</a>
										</div>
										<div class="prod_desc desc_home">
											<h3>
												<router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}"
													href="#">
													{{v.apd_name}}</router-link>
											</h3>
											<h4>{{v.apd_year}}</h4>
											<p>Rp {{(v.price)}}</p>
											<div class="rating_prod">
												<i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p class="text-center mb-0 mt-2">
							<router-link :to="{name:'CustomerFavourite'}" class="btn_act">{{web.lbl_view_all}}
							</router-link>
						</p>
						<div class="clearfix"></div>
					</div>
				</div>
				<div class="clear"></div>

				<div id="section-tickets" v-if="show_event=='true'"
					class="section page-section notopmargin nobottommargin bor_section bg_white   clearfix">
					<div class="container">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section">
									<h2>{{web.mn_event}}</h2>
								</div>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row">
							<div class="col-lg-6 col-md-12 col-12" v-for="(v,k) in data_event" :key="k">
								<div class="row">
									<div class="col-md-6">
										<div class="flip-card">
											<div class="front" :style="'background-image: url('+uploader(v.image)+');'">
												<div class="inner">
													<div class="feature-box media-box">
														<div class="date_wrapper">
															<h4>{{v.day}}</h4>
															<h3>{{v.month}}</h3>
															<p>{{v.year}}</p>
														</div>
													</div>
												</div>
											</div>
											<div class="back dark no-after"
												:style="'background-image: url('+uploader(v.image)+')'">
												<div class="inner mt-3"></div>
											</div>
										</div>
									</div>
									<div class="col-md-6 pl-md-0">
										<div class="wrap_event_p">
											<div class="event_wrap">
												<h3>
													<router-link :to="{name:'DetailEvent',params:{slug:v.slug}}">
														{{v.title}}</router-link>
												</h3>
												<p class="mb-3" v-html="(v.excerpt||'').limitChar(100)"></p>
												<router-link :to="{name:'DetailEvent',params:{slug:v.slug}}"
													class="btn_act">{{web.btn_view}}</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="section-speakers" v-if="show_blog=='true'"
					class="section  notopmargin nobottommargin page-section bor_section bg_white clearfix">
					<div class="container">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section_light">
									<h2>{{web.lbl_latest_blog}}</h2>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6 col-md-12 col-12" v-for="(v,k) in data_latest_blog" :key="k">
								<div class="blog_wrapper bg_brown">
									<div class="row">
										<div class="col-md-6">
											<div class="thumb_post">
												<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}"><img
														:src="uploader(v.image_view)" :alt="v.title" :title="v.title" />
												</router-link>
											</div>
										</div>
										<div class="col-md-6 pl-md-0">
											<div class="blog_content_post">
												<h3>
													<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
														{{v.title}}</router-link>
												</h3>
												<p v-html="(v.excerpt||'').limitChar(70)"></p>
												<ul>
													<li>
														<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
															{{(v.publish_date||0).dates('format')}}</router-link>
													</li>
													<li>
														<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
															{{v.author}}</router-link>
													</li>
												</ul>
												<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}"
													class="btn_act">{{web.btn_view}}</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="section-speakers" v-if="show_feed=='true' && data_feed.length"
					class="section notopmargin nobottommargin page-section bg_white     clearfix">
					<div class="container">
						<div class="row justify-content-around dark">
							<div class="col-md-12">
								<div class="heading_section">
									<h2>{{web.lbl_our_feed}}</h2>
								</div>
							</div>
						</div>
						<div class="row tight">
							<div v-for="(f,kf) in data_feed" :key="kf" class="col-md-4 col-sm-12" data-aos="fade-up"
								data-aos-duration="400">
								<div class="wrap_ig">
									<a href="javascript:;">
										<!-- <img :src="f.insta_thumbnail" :alt="f.insta_id" :title="f.insta_id" /> -->
										<VImg :lazySrc="f.insta_thumbnail"></VImg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>